import Vue from 'vue'
import { toast } from '@/assets/js/util'

export default function () {
  if (process.server) {
    return
  }

  require('./require-components')

  const XysMonitor = require('xys-monitor').default
  const VuePlugin = require('xys-monitor/plugin/vue').default
  const VueAwesomeSwiper = require('vue-awesome-swiper')
  Vue.use(VueAwesomeSwiper)

  XysMonitor.config({
    pageId: 180862601
  }).addPlugin(VuePlugin, Vue)

  document.querySelector('#app').addEventListener('touchmove', e => {
    if (e._prevent) {
      e.preventDefault()
    }
  })

  Vue.prototype.$toast = toast
}
