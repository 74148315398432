import Vue from 'vue'
import {
  Loading,
  Toast,
  DocPop,
  Login
} from '@xysfe/actui'

Vue.use(Loading)
Vue.use(Toast)
Vue.use(DocPop)
window.$Login = Login