import './assets/css/index.scss'
import 'swiper/swiper-bundle.css'
import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'
import clientOnly from './assets/js/client-only'
Vue.config.productionTip = false
import { DtraceProxy } from 'shunionreport'

const shTrace = DtraceProxy.getInstance()
shTrace.init({
  serviceName: 'indexV2_service'  // 标识业务名称
})
export default {
  createStoreAndRouter () {
    clientOnly()
    const store = createStore()
    const router = createRouter()
    sync(store, router)
  
    return { router, store }       
  },
  createApp (context, router, store) {
    const app = new Vue(Object.assign({}, context.rootOptions, {
      router,
      store,
      render: h => h(App)
    }))
  
    return { app, router, store }
  }
}
