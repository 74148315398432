export let isType = function (type) {
  return function (obj) {
    return Object.prototype.toString.call(obj) === '[object ' + type + ']'
  }
}
export let isObject = isType('Object')
export let isString = isType('String')
export let isNumber = isType('Number')
export let isBoolean = isType('Boolean')
export let isArray = Array.isArray || isType('Array')
export let isFunction = isType('Function')
export let isNull = function (obj) { return obj === null }
export let isUndefined = function (obj) { return obj === undefined }
export let isRegExp = isType('RegExp')
export let isError = isType('Error')

export function getCookie (key) {
  if (window.localStorage) {
    let value = localStorage.getItem(key)
    return value || ''
  } else {
    if (document.cookie.length > 0) {
      let cStart = document.cookie.indexOf(key + '=')
      if (cStart !== -1) {
        cStart = cStart + key.length + 1
        let cEnd = document.cookie.indexOf(';', cStart)
        if (cEnd === -1) cEnd = document.cookie.length
        return unescape(document.cookie.substring(cStart, cEnd))
      }
    }
    return ''
  }
}

export function setCookie (key, value) {
  if (window.localStorage) {
    try {
      localStorage.setItem(key, value)
    } catch (e) {
      console.log('当前可能处于无痕浏览模式')
    }
  } else {
    document.cookie = key + '=' + escape(value) + 'expires=1000'
  }
}

export function delCookie (key) {
  if (window.localStorage) {
    localStorage.removeItem(key)
  } else {
    document.cookie = name + '='
  }
}

export function throttle (func, wait, mustRun) {
  let timeout
  let startTime = new Date()
  return function () {
    let context = this
    let args = arguments
    let curTime = new Date()
    clearTimeout(timeout)
    // 如果达到了规定的触发时间间隔，触发 handler
    if (curTime - startTime >= mustRun) {
      func.apply(context, args)
      startTime = curTime
      // 没达到触发间隔，重新设定定时器
    } else {
      timeout = setTimeout(func, wait)
    }
  }
}

export function getParameter (name, str) {
  let r = new RegExp('(\\?|#|&)' + name + '=([^&#]*)(&|#|$)'); let m = str.match(r)
  return decodeURIComponent(!m ? '' : m[2])
}


export function escapeHTML(str){
  return str.replace(/[&<>'"]/g, tag => ({
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    "'": '&#39;',
    '"': '&quot;'
  }[tag] || tag))
}

export function unescapeHTML(str){
  return str.replace(/(&amp;|&lt;|&gt;|&#39;|&quot;")/g, tag => ({
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&#39;': '\'',
    '&quot;': '"'
  }[tag] || tag))
}

export function toast(tipText,timer){
  timer?timer:timer = 3000;
  //创建虚拟DOM
  var toastDom = document.createElement('div');
  toastDom.className = 'toastDom';
  toastDom.innerHTML = tipText;
  document.body.appendChild(toastDom);
  var thisToast = document.querySelectorAll('.toastDom');
  for(var i=0;i<thisToast.length;i++){
    thisToast[i].style.cssText = 'margin-left:-'+thisToast[i].scrollWidth/2+'px;'
  }
  setTimeout(function(){
    document.body.removeChild(toastDom)
  },timer)
}

/**
 * 通过href获取chn
 *
 * @returns chn值
 */
export function getChn () {
  let chn = location.search.match(/chn=(\w+)/)
  return chn && chn[1]
}

/**
 * 将href中的chn替换为newChn, 没有则添加chn
 * @param {href} url地址
 * @param {newChn}
 * @returns 新的连接
 */
export function changeChn(href, chnMap) {
  if (!href) { return href }
  const hasChn = /(\?|&)chn=/.test(href)
  const hasSubchn =/(\?|&)subchn=/.test(href)
  const { urlChn , pageChn, subChn } = chnMap
  let queryStr = ''
  let chn = ''
  if (urlChn) {
    chn = pageChn ? `${pageChn}_${urlChn}` : urlChn
  } else {
    chn = pageChn
  }
  if (chn) {
    if (hasChn) {  // 替换
      href = href.replace(/(\?|&)chn=(\w+)(&|#)?/, (_, $1, $2, $3) => {   // chn=h5index& h5index &
        let hasPageChn = pageChn && (String($2) === pageChn)  // 原链接chn等于pageChn，过滤掉原链接的chn
        if(String(chn) === $2 || hasPageChn ) {  // chn 跟原本的chn相同，过滤掉
          return `${$1}chn=${chn}${$3 || ''}`
        } else {
          return `${$1}chn=${$2}_${chn}${$3 || ''}`
        }
      })
    } else {  // 新增
      queryStr = `chn=${chn}`
    }
  }
  if (subChn) {
    if (hasSubchn) {
      href = href.replace(/(\?|&)subchn=(\w+)(&|#)?/, (_, $1, $2, $3) => {
        if(String(subChn) === String($2)) {
          return `${$1}subchn=${subChn}${$3 || ''}`
        } else {
          return `${$1}subchn=${$2}_${subChn}${$3 || ''}`
        }
      })
    } else {
      queryStr = queryStr ? `${queryStr}&subchn=${subChn}` : `subchn=${subChn}`
    }
  }

  if(queryStr) {
    href = addQueryString(href,queryStr)
  }

  return href
}

function addQueryString (href, queryStr) {
  if (href.indexOf('?') > -1) {
    queryStr ? href = href.replace('?', `?${queryStr}&`) : null
  } else if (href.indexOf('#') > -1) {
    queryStr ? href = href.replace('#', `?${queryStr}#`) : null
  } else {
    queryStr ? href = `${href}?${queryStr}` : null
  }
  return href
}

let rAF = function (...args) {
  let fn = window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          function (callback) { window.setTimeout(callback, 1000 / 60) }
  fn(...args)
}

/**
 * 滚动到页面顶部
 *
 * @param {*} {speed, callback} 滚动速度和回调函数
 */
 export function scrollToTop ({ callback }) {
  let doc = document.documentElement
  let body = document.body // 兼容微信
  let scrollDataList = _genScrollList(doc.scrollTop || body.scrollTop)
  rAF(scrollStep)
  callback.call(this)
  function scrollStep () {
    if (doc.scrollTop) {
      doc.scrollTop = scrollDataList.shift()
    } else if (body.scrollTop) {
      body.scrollTop = scrollDataList.shift()
    }
    if (scrollDataList.length) {
      rAF(scrollStep)
    }
  }
}

function _genScrollList (dist) {
  let minsp = 20; let maxsp = 250; let step = 10; let cursp = minsp; let curdist = dist; let distArr = []
  const slowTh = Math.min(((maxsp - minsp) / step + 1) * (minsp + maxsp) / 2, dist / 2)
  while (curdist > 0) {
    curdist -= cursp
    if (curdist >= slowTh) {
      // 加速或保持匀速
      cursp < maxsp && (cursp += step)
      cursp > maxsp && (cursp = maxsp)
    } else {
      // 减速
      cursp > minsp && (cursp -= step)
      cursp < minsp && (cursp = minsp)
    }
    curdist < 0 && (curdist = 0)
    distArr.push(curdist)
  }
  return distArr
}