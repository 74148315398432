import Vue from 'vue'
import Vuex from 'vuex'
import { changeChn } from '@/assets/js/util'

Vue.use(Vuex)

export function createStore () {
  return new Vuex.Store({
    state: {
      hasInit: false,
      indexBanner: [],
      indexRecIconNew: [],
      welfare: {},
      list: [],
      isNew: 1,
      chn: '',
      pageChn: 'h5index',
      subChn: '',
      questionareList: [
        // {
        //   "title":["考虑为哪位","家庭成员配置保险？"],
        //   "multiSelect":false,
        //   "answers":[
        //     {"label":"自己&配偶","value":"couple","tips":"test tips","active":false},
        //     {"label":"宝宝","value":"baby","tips":"test tips","active":false},
        //     {"label":"父母","value":"parents","tips":"test tips","active":false}
        //   ]
        // },{
        //   "title":["考虑配置","什么类型的保险？"],
        //   "multiSelect":true,
        //   "answers":[
        //     {"label":"重疾险","value":"verySick","tips":"test tips","active":false},
        //     {"label":"意外险","value":"accident","tips":"test tips","active":false},
        //     {"label":"百万医疗险","value":"medical","tips":"test tips","active":false},
        //     {"label":"财富保险","value":"fonture","tips":"test tips","active":false},
        //     {"label":"寿险","value":"life","tips":"test tips","active":false}
        //   ]
        // }
      ],
      questionareVisible: false,
      isBigEvent: false,
      isSpecialActivity: false
    },
    getters :{
      chnMap (state) {
        return {
          urlChn: state.chn,
          pageChn: state.pageChn,
          subChn: state.subChn
        }
      },
      chnSuffix (state) {
        let str = ''
        if (state.chn) {
          str =  `chn=${state.chn}`
        } 
        if(state.subChn) {
          str = str ? `${str}&subchn=${state.subChn}` : `subchn=${state.subChn}`
        }
        return str
      },
    },
    mutations: {
      updateState (state, param) {
        if (param.constructor === Object) {
          state[param.key] = param.value
        } else if (param.constructor === Array) {
          for (let i = 0; i < param.length; i++) {
            state[param[i].key] = param[i].value
          }
        }
      },
      initNewWelfare(state, welfare){
        if(state.isNew || String(state.isNew) === '0'){
          state.welfare = {
            ...welfare,
            url: changeChn(welfare.url, {
              urlChn: state.chn,
              pageChn: state.pageChn,
              subChn: state.subChn
            })
          }
        }
      }
    },
    actions: {
      getFirstScreen ({ commit , dispatch }, { vueInstance, postData = { } }) {
        return vueInstance.$$fetch({ 
          url: '/index/getFirstScreen', 
          data: {
            ...postData,
            questVer:1
          },
          cache: {
            cacheable: true,
            expireTime: 1000 * 60 * 2 //数据2分钟有效
          }
        }).then(res => {
          const { ret, data, errmsg, msg } = res || {}
          if (Number(ret) === 0) {
            commit('updateState', { key: 'hasInit', value: true }) 
            return dispatch('handleFirstScreenData', data)
            
          } else {
            return Promise.reject(errmsg || msg)
          } 
        }).catch(e => {
          return Promise.reject(e)
        })
      },
      handleFirstScreenData({ commit }, data ){
        let questionareList = data.questionareList || []
        if( questionareList.length){
          commit('updateState', { key: 'questionareList', value: questionareList })
          commit('updateState', { key: 'questionareVisible', value: true })
        }
        commit('updateState', { key: 'isNew', value: data.isNew })
        commit('updateState', { key: 'indexBanner', value: data.indexBanner || []})
        commit('updateState', { key: 'indexRecIconNew', value: data.indexRecIconNew || []})
        if(data.welfare){
          commit('initNewWelfare', data.welfare)
        }
        let productList = data.productList || []
        if(productList.length){
          commit('updateState', { key: 'list', value: productList })
        }
        const bgGray = data.bigEvent ? (data.bigEvent.bg_gray || 0) : 0
        commit("updateState", {
          key: "isBigEvent",
          value: !!bgGray && Number(bgGray) === 1
        })
        if (data.isSpecialActivity) {
          commit("updateState", {
            key: "isSpecialActivity",
            value: true
          })
        }
      }
    }
  })
}
